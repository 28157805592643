import React, { useEffect, useState } from 'react';
import { defaults } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'next-i18next';
import Check from '../../public/img/legend-check.svg';
import classNames from 'classnames';
import debounce from 'debounce';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import 'chart.js/auto';

import styles from './ProductDiagram.module.scss';

const ProductDiagram = ({ title = '', sheets = [], note = '' }) => {
    // // Bypass for SSR
    if (typeof window === 'undefined') {
        return null;
    }

    defaults.font.family = 'Inter, sans-serif';
    const { t } = useTranslation();
    const palette = [
        'rgba(0, 91, 117, 1)',
        'rgba(254, 202, 1, 1)',
        'rgba(15, 177, 193, 1)',
        'rgba(237, 111, 136, 1)',
        'rgba(29, 172, 44, 1)',
    ];
    const unit = 'Öre/kWh';
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [mounted, setMounted] = useState(false);
    const [sheet, setSheet] = useState(
        sheets.length > 0 ? sheets[0].file.url : null
    );
    const [downloadSheet, setDownloadSheet] = useState(
        sheets.length > 0 ? sheets[0].downloadFile.url : null
    );
    const [displayYears, setDisplayYears] = useState(2);
    const [addDiscount, setAddDiscount] = useState(false);
    const [hasDiscount, setHasDiscount] = useState(false);
    const [minMax, setMinMax] = useState([99999, 0]);
    const [activeDatasets, setActiveDatasets] = useState([true, true]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        setMounted(true);
        getChartData();

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getChartData();
    }, [displayYears, sheet, addDiscount, activeDatasets, windowWidth]);

    const handleResize = debounce(
        () => setWindowWidth(window.innerWidth),
        1000
    );

    const handleLegendClick = (datasetIndex) => {
        setActiveDatasets((prevActiveDatasets) => {
            const updatedActiveDatasets = [...prevActiveDatasets];
            updatedActiveDatasets[datasetIndex] =
                !updatedActiveDatasets[datasetIndex];
            return updatedActiveDatasets;
        });
    };

    const updateMinMax = (values) => {
        setMinMax((prevMinMax) => {
            const [currentMinValue, currentMaxValue] = prevMinMax;

            const newMinValue = Math.min(currentMinValue, ...values);
            const newMaxValue = Math.max(currentMaxValue, ...values);

            return [newMinValue, newMaxValue];
        });
    };

    const getChartData = () => {
        Papa.parse(`${sheet}`, {
            download: true,
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                const dataByYear = result.data.reduce((acc, item) => {
                    const year = item.Year;

                    if (!acc[year]) {
                        acc[year] = [];
                    }

                    acc[year].push(item);
                    return acc;
                }, {});

                const keys = Object.keys(dataByYear);
                const firstKey = keys[0];
                const lastKey = keys[keys.length - 1];
                const datasets = [];
                let lastDisplayYears = {};
                let labels = [];

                if (displayYears === 2) {
                    lastDisplayYears = Object.fromEntries(
                        Object.entries(dataByYear).slice(-(displayYears + 1))
                    );
                    const months = Object.keys(dataByYear[firstKey][0]).slice(
                        2,
                        -1
                    );
                    let values = {};
                    let discount = false;

                    for (const year in lastDisplayYears) {
                        const monthsAndYear = months.map(
                            (item) => `${item} ${year}`
                        );
                        labels = [...labels, ...monthsAndYear];

                        const yearData = dataByYear[year];

                        yearData.forEach((item) => {
                            const label = item[''];
                            discount = item.Discount;
                            setHasDiscount(Boolean(discount));

                            if (!values[label]) {
                                values[label] = [];
                            }

                            values[label].push(
                                ...Object.values(item).slice(2, -1)
                            );
                        });
                    }

                    let emptyPos = 36;

                    for (const key in values) {
                        const valuesArray = values[key];
                        emptyPos = valuesArray.findIndex(
                            (value) => value === ''
                        );
                        emptyPos = emptyPos === -1 ? 36 : emptyPos;
                    }

                    labels = labels.slice(emptyPos - 24, emptyPos);

                    for (const key in values) {
                        const valuesArray = values[key];
                        let finalValues = valuesArray
                            .slice(emptyPos - 24, emptyPos)
                            .map((item) => parseFloat(item));

                        if (addDiscount && discount) {
                            finalValues.forEach((value, index) => {
                                finalValues[index] = value - discount;
                            });
                        }

                        values[key] = finalValues;

                        datasets.push({
                            label: `${key}`,
                            data: values[key],
                        });
                    }
                } else {
                    const availableYearsLength = Object.keys(dataByYear).length;
                    const averagePerYear = {};
                    labels = keys.slice(
                        availableYearsLength - (displayYears + 1),
                        availableYearsLength - 1
                    );
                    lastDisplayYears = Object.fromEntries(
                        Object.entries(dataByYear).slice(
                            availableYearsLength - (displayYears + 1),
                            availableYearsLength - 1
                        )
                    );

                    for (const year in lastDisplayYears) {
                        const yearData = dataByYear[year];

                        yearData.forEach((item) => {
                            const label = item[''];
                            const values = Object.values(item).slice(2, -1);
                            const discount = item.Discount;
                            updateMinMax(values);
                            setHasDiscount(Boolean(discount));

                            if (addDiscount && discount) {
                                values.forEach((value, index) => {
                                    values[index] = value - discount;
                                });
                            }

                            const average =
                                values.reduce(
                                    (sum, value) => sum + parseFloat(value),
                                    0
                                ) / values.length;

                            if (!averagePerYear[label]) {
                                averagePerYear[label] = [];
                            }

                            averagePerYear[label].push(average.toFixed(2));
                        });
                    }

                    for (const label in averagePerYear) {
                        datasets.push({
                            label: `${label}`,
                            data: averagePerYear[label],
                        });
                    }
                }

                setChartData({
                    labels,
                    datasets: datasets.map((item, index) => ({
                        ...item,
                        backgroundColor: activeDatasets[index]
                            ? palette[index]
                            : palette[index].replace(/[^,]+(?=\))/, '0.1'),
                        borderColor: activeDatasets[index]
                            ? palette[index]
                            : palette[index].replace(/[^,]+(?=\))/, '0.1'),
                        borderRadius: 5,
                        maxBarThickness: 70,
                        pointHoverRadius: 10,
                        pointRadius: windowWidth < 768 ? 4 : 6,
                        dimmed: false,
                    })),
                });
            },
        });
    };

    // const handleSetSheet = (e) => {
    //     setSheet(e.target.value);
    // };

    const handleSetSheet = (e) => {
        const sheet = sheets[e.target.value].file.url;
        const downloadSheet = sheets[e.target.value].downloadFile.url;
        setSheet(sheet);
        setDownloadSheet(downloadSheet);
    };

    const handleSetDiscount = (e) => {
        setAddDiscount(parseInt(e.target.value));
    };

    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#005b75',
                    maxTicksLimit: 12,
                },
            },
            y: {
                ticks: {
                    callback: function (value, index) {
                        if (index === 0) return 'Öre/kWh';
                        return value;
                    },
                    color: '#005b75',
                    padding: 15,
                },
                // suggestedMax: minMax[1],
            },
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                mode: 'index',
                intersect: true,
                position: 'nearest',
                backgroundColor: '#ffffff',
                bodyColor: '#005b75',
                borderColor: '#005b75',
                borderWidth: 1,
                boxPadding: 8,
                padding: 10,
                pointStyle: 'circle',
                titleColor: '#005b75',
                titleFont: {
                    size: 16,
                    weight: 'bold',
                },
                usePointStyle: true,
                callbacks: {
                    label: ({ dataset, formattedValue }) => {
                        return `${dataset.label}: ${formattedValue} ${unit}`;
                    },
                    afterBody: (data) => {
                        return `\n Diff: ${((Math.abs(data[0].raw - data[1].raw) * 100) / 100).toFixed(2)} Öre/kWh`;
                    },
                },
            },
        },
    };

    return (
        mounted && (
            <div className={styles['ProductDiagram']}>
                {chartData ? (
                    <div className={styles['ProductDiagram__Container']}>
                        {!!title && (
                            <h2 className={styles['ProductDiagram__Title']}>
                                {title}
                            </h2>
                        )}
                        <div className={styles['ProductDiagram__Filters']}>
                            <span
                                className={[
                                    styles['ProductDiagram__Buttons'],
                                    styles[
                                        'ProductDiagram__Buttons--AlignLeft'
                                    ],
                                ].join(' ')}>
                                <button
                                    className={classNames(
                                        styles['ProductDiagram__Button'],
                                        {
                                            [styles[
                                                'ProductDiagram__Button--Active'
                                            ]]: displayYears === 2,
                                        }
                                    )}
                                    onClick={() => setDisplayYears(2)}>
                                    2 {t('ProductDiagram.years')}
                                </button>
                                <button
                                    className={classNames(
                                        styles['ProductDiagram__Button'],
                                        {
                                            [styles[
                                                'ProductDiagram__Button--Active'
                                            ]]: displayYears === 6,
                                        }
                                    )}
                                    onClick={() => setDisplayYears(6)}>
                                    6 {t('ProductDiagram.years')}
                                </button>
                            </span>
                            <span
                                className={
                                    styles['ProductDiagram__FiltersRight']
                                }>
                                {hasDiscount && (
                                    <>
                                        <span
                                            className={classNames([
                                                styles[
                                                    'ProductDiagram__Buttons'
                                                ],
                                                styles[
                                                    'ProductDiagram__Buttons--NoMobile'
                                                ],
                                            ])}>
                                            <button
                                                className={classNames(
                                                    styles[
                                                        'ProductDiagram__Button'
                                                    ],
                                                    {
                                                        [styles[
                                                            'ProductDiagram__Button--Active'
                                                        ]]: addDiscount,
                                                    }
                                                )}
                                                onClick={() =>
                                                    setAddDiscount(true)
                                                }>
                                                {t(
                                                    'ProductDiagram.withDiscount'
                                                )}
                                            </button>
                                            <button
                                                className={classNames(
                                                    styles[
                                                        'ProductDiagram__Button'
                                                    ],
                                                    {
                                                        [styles[
                                                            'ProductDiagram__Button--Active'
                                                        ]]: !addDiscount,
                                                    }
                                                )}
                                                onClick={() =>
                                                    setAddDiscount(false)
                                                }>
                                                {t(
                                                    'ProductDiagram.withoutDiscount'
                                                )}
                                            </button>
                                        </span>
                                        <span
                                            className={classNames([
                                                styles[
                                                    'ProductDiagram__Buttons'
                                                ],
                                                styles[
                                                    'ProductDiagram__Buttons--NoDesktop'
                                                ],
                                            ])}>
                                            <label className={styles['ProductDiagram__SelectLabel']} for="discount">
                                                {t('ProductDiagram.chooseDiscount')}
                                            </label>
                                            <select
                                                id="discount"
                                                className={
                                                    styles[
                                                        'ProductDiagram__Select'
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    handleSetDiscount(e)
                                                }>
                                                <option
                                                    key="discount_0"
                                                    value={0}>
                                                    {t(
                                                        'ProductDiagram.withoutDiscount'
                                                    )}
                                                </option>
                                                <option
                                                    key="discount_1"
                                                    value={1}>
                                                    {t(
                                                        'ProductDiagram.withDiscount'
                                                    )}
                                                </option>
                                            </select>
                                        </span>
                                    </>
                                )}
                                <span
                                    className={
                                        styles['ProductDiagram__Buttons']
                                    }>
                                    <label className={styles['ProductDiagram__SelectLabel']} for="area">
                                        {t('ProductDiagram.chooseArea')}
                                    </label>
                                    <select
                                        className={
                                            styles['ProductDiagram__Select']
                                        }
                                        id="area"
                                        onChange={(e) => handleSetSheet(e)}>
                                        {sheets.map((item, index) => (
                                            <option key={index} value={index}>
                                                {item.file.title}
                                            </option>
                                        ))}
                                    </select>
                                </span>
                                <span
                                    className={classNames([
                                        styles['ProductDiagram__Buttons'],
                                        styles[
                                            'ProductDiagram__Buttons--NoMobile'
                                        ],
                                    ])}>
                                    <a
                                        className={
                                            styles['ProductDiagram__Download']
                                        }
                                        href={
                                            downloadSheet
                                                ? downloadSheet
                                                : sheet
                                        }
                                        download>
                                        <span className="sr-only">
                                            {t('ProductDiagram.download')}
                                        </span>
                                    </a>
                                </span>
                            </span>
                        </div>
                        <div className={styles['ProductDiagram__Chart']}>
                            <Line options={chartOptions} data={chartData} />
                        </div>
                        <span className={styles['ProductDiagram__Legend']}>
                            {chartData.datasets.map((item, index) => (
                                <span
                                    role="button"
                                    className={classNames(
                                        styles['ProductDiagram__LegendButton'],
                                        {
                                            [styles[
                                                'ProductDiagram__LegendButton--Dark'
                                            ]]: index === 0,
                                            [styles[
                                                'ProductDiagram__LegendButton--Active'
                                            ]]: activeDatasets[index],
                                        }
                                    )}
                                    key={index}
                                    onClick={() => handleLegendClick(index)}
                                    style={{ '--color': palette[index] }}>
                                    <span
                                        className={
                                            styles[
                                                'ProductDiagram__LegendButtonCheck'
                                            ]
                                        }>
                                        <Check />
                                    </span>
                                    {item.label}
                                </span>
                            ))}
                        </span>
                        <p className={styles['ProductDiagram__Note']}>{note}</p>
                    </div>
                ) : (
                    <div>Loading...</div>
                )}
            </div>
        )
    );
};

ProductDiagram.propTypes = {
    title: PropTypes.string,
    sheets: PropTypes.array,
};

export default ProductDiagram;
